<template>
  <div class="header-container">
    <div class="top-bar">
      <div class="top-bar__left">
        <div class="header-mobile landing-top-menu">
          <img
            src="../assets/img/icons/menu.png"
            @click="mostrarMiAltaMenu = true"
          />
        </div>
        <img
          v-if="$usuario && $usuario.rolClientes != null"
          class="icon_default header-desktop"
          src="../assets/img/icons/profile_white.png"
          alt=""
        />
        <a
          class="header-desktop"
          v-if="$usuario && $usuario.rolClientes != null"
          @click="irA('mialta')"
          >Mi Alta</a
        >
        <div
          v-if="$usuario && $usuario.rolClientes != null"
          class="vl header-desktop"
        />
        <img
          class="icon_default header-desktop"
          src="../assets/img/icons/folder_white.png"
          alt=""
        />
        <a class="header-desktop" @click="irA('documentos')">Mis Documentos</a>
      </div>

      <div class="landing-top-hola header-desktop">
        <div>
          <p class="landing-top-hola-nombre">
            <b>{{ getNombre() }}</b>
          </p>
          <p class="landing-top-logout header__email">
            {{ $usuario.email }}
          </p>
        </div>
        <div
          class="landing-top-user-img-container "
          @click="mostrarLogoutMenu = true"
        >
          <img
            v-if="$usuario.imagen == null"
            class="landing-top-user-img"
            src="../assets/img/default.png"
          />
          <img
            class="landing-top-user-img"
            v-else
            :src="$localurl + '/' + $usuario.imagen"
          />
          <img
            v-if="getReemplazoId() && getReemplazanteImagen()"
            class="landing-top-user-img landing-top-reemplazo-img"
            :src="$localurl + '/' + getReemplazanteImagen()"
          />
          <img
            v-else-if="getReemplazoId()"
            class="landing-top-user-img landing-top-reemplazo-img"
            src="../assets/img/default.png"
          />
          <img
            class="landing-top-user-menu"
            src="../assets/img/flechas/triangle_bottom.png"
          />
        </div>
      </div>
      <div v-show="mostrarLogoutMenu" class="landing-top-logout-menu">
        <div
          class="landing-top-logout-menu-mask"
          @click="mostrarLogoutMenu = false"
        ></div>
        <div class="landing-top-logout-menu-popup">
          <label
            v-if="
              $usuario.rolClientes && $usuario.rolClientes.rol != 'Distribuidor'
            "
            @click="irAMiCuenta()"
            >Mi cuenta</label
          >
          <label
            v-if="
              $usuario.rolClientes &&
                $usuario.rolClientes.rol != 'Distribuidor' &&
                tieneReemplazos()
            "
            @click="irASeleccionarCuenta()"
            >Seleccionar cuenta</label
          >
          <label class="landing-top-logout-menu-popup-logout" @click="logout()"
            >Cerrar sesión</label
          >
        </div>
      </div>
      <!-- MOBILE >>> -->
      <div
        class="landing-top-user-img-container header-mobile"
        @click="mostrarLogoutMenu = true"
      >
        <img
          v-if="mostrarLogoutMenu"
          class="landing-top-user-menu arrow-icon header-mobile"
          src="../assets/img/flechas/chevron-up.svg"
        />
        <img
          v-if="!mostrarLogoutMenu"
          class="landing-top-user-menu arrow-icon header-mobile"
          src="../assets/img/flechas/chevron-down.svg"
        />
        <img
          v-if="$usuario.imagen == null"
          class="landing-top-user-img header-mobile"
          src="../assets/img/default.png"
        />
        <img
          class="landing-top-user-img header-mobile"
          v-else
          :src="$localurl + '/' + $usuario.imagen"
        />
        <img
          v-if="getReemplazoId() && getReemplazanteImagen()"
          class="landing-top-user-img landing-top-reemplazo-img header-mobile"
          :src="$localurl + '/' + getReemplazanteImagen()"
        />
        <img
          v-else-if="getReemplazoId()"
          class="landing-top-user-img landing-top-reemplazo-img header-mobile"
          src="../assets/img/default.png"
        />
      </div>
      <div v-show="mostrarMiAltaMenu" class="landing-top-logout-menu">
        <div
          class="landing-top-logout-menu-mask"
          @click.stop="mostrarMiAltaMenu = false"
        ></div>
        <div class="landing-top-mialta-menu-popup">
          <label v-if="$usuario.rolClientes != null" @click="irA('mialta')"
            >Mi Alta</label
          >
          <label @click="irA('documentos')">Mis Documentos</label>
        </div>
      </div>
    </div>

    <div class="header-logo">
      <img src="../assets/img/logo.png" alt="logo" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      mostrarMiAltaMenu: false,
      mostrarLogoutMenu: false
    };
  },
  mounted() {
    this.$eventHub.$on("update", () => this.$forceUpdate());
  },
  methods: {
    logout() {
      localStorage.removeItem("token-landing");
      this.$store.commit("setReemplazante", null);
      localStorage.removeItem("reemplazo-id");
      this.$cookies.remove("token-landing");
      const that = this;
      this.$axios
        .get(this.$localurl + "/perform_logout")
        .then(function() {
          Vue.prototype.$usuario = {
            rol: {},
            rolClientes: {}
          };
          that.$router.push("/login");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    redirectToHome() {
      this.$router.push("/");
    },
    getReemplazoId() {
      return localStorage.getItem("reemplazo-id");
    },
    irASeleccionarCuenta() {
      this.$router.push("/seleccionarCuenta");
    },
    irAMiCuenta() {
      localStorage.setItem("mialta-irA-micuenta", true);
      this.irA("mialta");
    },
    irA(text) {
      this.postMetrica(text);
      const that = this;
      if (text != "planes") {
        let token = this.$cookies.get("token-landing");
        if (token == null) {
          token = localStorage.getItem("token-landing");
        }
        let ruta = "";
        switch (text) {
          case "mialta":
            ruta = this.$mialta_url + "/landing/" + token;
            break;
          case "cyo":
            ruta = this.$cyo_url + "/landing/" + token;
            break;
          case "cc":
            if (this.$dev) {
              ruta =
                "https://dev.micuenta.basf.com.ar/cuenta-corriente/landing/" +
                token;
            } else {
              ruta =
                "https://micuenta.agro.basf.com/cuenta-corriente/landing/" +
                token;
            }
            break;
          case "pog":
            ruta = this.$pog_url + "/landing/" + token;
            break;
          case "simulador":
            ruta = this.$dev
              ? "https://dev.micuenta.basf.com.ar/simulador"
              : "https://micuenta.agro.basf.com/simulador";
            if (this.$usuario.email.toLowerCase().includes("basf.com")) {
              ruta =
                ruta +
                "/plancomercial/planificacion?auth=" +
                token +
                "&admin=true";
            } else ruta = ruta + "/landing?auth=" + token;
            break;
          case "documentos":
            if (this.$dev) {
              ruta =
                "https://dev.micuenta.basf.com.ar/misdocumentos/token/" + token;
            } else {
              ruta =
                "https://micuenta.agro.basf.com/misdocumentos/token/" + token;
            }
            break;
          case "mitienda":
            window
              .open(
                "https://mitiendabasf.com.ar?token=" + this.miTiendaToken,
                "_blank"
              )
              .focus();
            break;
          default:
            break;
        }
        if (ruta != "") {
          this.mostrarLanding = false;
          setTimeout(function() {
            that.mostrarImagen = true;
            setTimeout(function() {
              that.mostrarListo = true;
              window.location.href = ruta;
            }, 700);
          }, 800);
        }
      } else {
        this.mostrarLanding = false;
        this.mostrarImagen = true;
        this.$axios
          .get(this.$localurl + "/api/planes")
          .then(function(response) {
            console.log(response);
            that.mostrarListo = true;
            window.location.href = response.data;
          })
          .catch(function(response) {
            console.log(response);
          });
      }
    },
    getReemplazanteImagen() {
      const reemplazante = this.$store.getters.reemplazante;
      return reemplazante != null ? reemplazante.imagen : null;
    },
    tieneReemplazos() {
      if (
        this.$store.getters.reemplazos != null &&
        this.$store.getters.reemplazos.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    postMetrica(modulo) {
      this.$axios
        .post(this.$localurl + "/api/metrica/" + modulo)
        .then(function(response) {
          console.log(response);
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    getNombre() {
      if (
        this.$usuario.nombre != null &&
        this.$usuario.email.split("@")[1].split(".")[0] == "basf"
      ) {
        return this.$usuario.nombre.split(" ")[0];
      } else if (this.$usuario.nombre != null) {
        return this.$usuario.nombre;
      } else {
        return "";
      }
    }
  },

  computed: {
    imageSrc: function() {
      return this.$usuario != null &&
        this.$usuario.imagen != null &&
        this.$usuario.imagen.length < 100
        ? this.$usuario.imagen
        : "";
    }
  }
};
</script>
<style src="@/assets/css/components/header.css"></style>
