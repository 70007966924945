<script>
import DeleteToolModal from "./DeleteToolModal.vue";

export default {
  data() {
    return {
      deleteModalIsOpen: false
    };
  },
  props: {
    herramienta: Object
  },
  mounted() {
    this.$eventHub.$on("update", () => this.$forceUpdate());
  },
  methods: {
    openDeleteToolModal() {
      this.$eventHub.$emit("delete-tool-modal-open", true);
    },
    editTool() {
      this.$eventHub.$emit("open-all-my-apps-modal", false);
      this.$eventHub.$emit("herramienta-editar", this.herramienta);
    }
  },
  components: { DeleteToolModal }
};
</script>
<template>
  <transition name="fade-fast">
    <div>
      <a
        class="app-card__container"
        :href="herramienta.link"
        target="_blank"
        rel="noopener"
      >
        <div class="app-card__header">
          <img
            class="app-card__icon"
            :src="$localurl + '/' + herramienta.imagen"
            :alt="herramienta.imagenNombre"
          />
          <button class="app-card__action-button" @click.prevent="editTool">
            <img
              class="app-card__edit-icon "
              src="../../assets/img/icons/edit.svg"
            />
          </button>
          <button
            class="app-card__action-button"
            @click.prevent="openDeleteToolModal"
          >
            <img src="../../assets/img/icons/dark-delete.svg" />
          </button>
        </div>
        <p class="app-card__name">{{ herramienta.nombre }}</p>
        <div v-if="deleteModalIsOpen"></div>
      </a>
      <DeleteToolModal :herramienta="herramienta" />
    </div>
  </transition>
</template>
<style src="../../assets/css/components/app-card.css"></style>
